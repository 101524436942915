import { render, staticRenderFns } from "./FullScreenImg.vue?vue&type=template&id=2c1d27cc&scoped=true&"
var script = {}
import style0 from "./FullScreenImg.vue?vue&type=style&index=0&id=2c1d27cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c1d27cc",
  null
  
)

export default component.exports